import { CHAIN_ID_BSC, CHAIN_ID_ETH, CHAIN_ID_SOLANA, CHAIN_ID_TERRA, CHAIN_ID_POLYGON, CHAIN_ID_ETHEREUM_ROPSTEN, } from "./consts";
import { humanAddress, canonicalAddress, isNativeDenom } from "../terra";
import { PublicKey } from "@solana/web3.js";
import { hexValue, hexZeroPad, stripZeros } from "ethers/lib/utils";
import { arrayify, zeroPad } from "@ethersproject/bytes";
export var isEVMChain = function (chainId) {
    return chainId === CHAIN_ID_ETH ||
        chainId === CHAIN_ID_BSC ||
        chainId === CHAIN_ID_ETHEREUM_ROPSTEN ||
        chainId === CHAIN_ID_POLYGON;
};
export var isHexNativeTerra = function (h) { return h.startsWith("01"); };
export var nativeTerraHexToDenom = function (h) {
    return Buffer.from(stripZeros(hexToUint8Array(h.substr(2)))).toString("ascii");
};
export var uint8ArrayToHex = function (a) {
    return Buffer.from(a).toString("hex");
};
export var hexToUint8Array = function (h) {
    return new Uint8Array(Buffer.from(h, "hex"));
};
export var hexToNativeString = function (h, c) {
    try {
        return !h
            ? undefined
            : c === CHAIN_ID_SOLANA
                ? new PublicKey(hexToUint8Array(h)).toString()
                : isEVMChain(c)
                    ? hexZeroPad(hexValue(hexToUint8Array(h)), 20)
                    : c === CHAIN_ID_TERRA
                        ? isHexNativeTerra(h)
                            ? nativeTerraHexToDenom(h)
                            : humanAddress(hexToUint8Array(h.substr(24))) // terra expects 20 bytes, not 32
                        : h;
    }
    catch (e) { }
    return undefined;
};
export var nativeToHexString = function (address, chain) {
    if (!address || !chain) {
        return null;
    }
    if (isEVMChain(chain)) {
        return uint8ArrayToHex(zeroPad(arrayify(address), 32));
    }
    else if (chain === CHAIN_ID_SOLANA) {
        return uint8ArrayToHex(zeroPad(new PublicKey(address).toBytes(), 32));
    }
    else if (chain === CHAIN_ID_TERRA) {
        if (isNativeDenom(address)) {
            return ("01" +
                uint8ArrayToHex(zeroPad(new Uint8Array(Buffer.from(address, "ascii")), 31)));
        }
        else {
            return uint8ArrayToHex(zeroPad(canonicalAddress(address), 32));
        }
    }
    else {
        return null;
    }
};
export var uint8ArrayToNative = function (a, chainId) {
    return hexToNativeString(uint8ArrayToHex(a), chainId);
};
