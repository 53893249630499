/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { utils, Contract, ContractFactory } from "ethers";
var _abi = [
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "hash",
                type: "bytes32",
            },
        ],
        name: "governanceActionIsConsumed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "impl",
                type: "address",
            },
        ],
        name: "isInitialized",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "hash",
                type: "bytes32",
            },
        ],
        name: "isTransferCompleted",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "wormhole",
        outputs: [
            {
                internalType: "contract IWormhole",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "chainId",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "governanceChainId",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "governanceContract",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "tokenChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "tokenAddress",
                type: "bytes32",
            },
        ],
        name: "wrappedAsset",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "chainId_",
                type: "uint16",
            },
        ],
        name: "bridgeContracts",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "tokenImplementation",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "isWrappedAsset",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "splCache",
        outputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "name",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "symbol",
                        type: "bytes32",
                    },
                ],
                internalType: "struct NFTBridgeStorage.SPLCache",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
var _bytecode = "0x608060405234801561001057600080fd5b50610384806100206000396000f3fe608060405234801561001057600080fd5b50600436106100b45760003560e01c80639a8a0592116100715780639a8a0592146101f8578063aa4efa5b14610213578063ad66a5f114610236578063b172b22214610268578063d60b347f14610270578063fbe3c2cd1461029c57600080fd5b80631a2be4da146100b95780631ff1e286146100fa5780632c3c02a41461014c5780632f3a3d5d1461016f5780633ca648261461018057806384acd1bb146101e7575b600080fd5b6100e56100c73660046102c5565b6001600160a01b031660009081526008602052604090205460ff1690565b60405190151581526020015b60405180910390f35b610134610108366004610325565b61ffff91909116600090815260076020908152604080832093835292905220546001600160a01b031690565b6040516001600160a01b0390911681526020016100f1565b6100e561015a3660046102f3565b60009081526004602052604090205460ff1690565b6001546001600160a01b0316610134565b6101cc61018e3660046102f3565b6040805180820190915260008082526020820152506000908152600a6020908152604091829020825180840190935280548352600101549082015290565b604080518251815260209283015192810192909252016100f1565b6000546001600160a01b0316610134565b60025461ffff165b60405161ffff90911681526020016100f1565b6100e56102213660046102f3565b60009081526005602052604090205460ff1690565b61025a61024436600461030b565b61ffff1660009081526009602052604090205490565b6040519081526020016100f1565b60035461025a565b6100e561027e3660046102c5565b6001600160a01b031660009081526006602052604090205460ff1690565b60025462010000900461ffff16610200565b803561ffff811681146102c057600080fd5b919050565b6000602082840312156102d6578081fd5b81356001600160a01b03811681146102ec578182fd5b9392505050565b600060208284031215610304578081fd5b5035919050565b60006020828403121561031c578081fd5b6102ec826102ae565b60008060408385031215610337578081fd5b610340836102ae565b94602093909301359350505056fea2646970667358221220adb0d70f0ee780ca144eefaa3a69d0407d37aded1d0f3cf3a1d719327225548964736f6c63430008040033";
var NFTBridgeGetters__factory = /** @class */ (function (_super) {
    __extends(NFTBridgeGetters__factory, _super);
    function NFTBridgeGetters__factory(signer) {
        return _super.call(this, _abi, _bytecode, signer) || this;
    }
    NFTBridgeGetters__factory.prototype.deploy = function (overrides) {
        return _super.prototype.deploy.call(this, overrides || {});
    };
    NFTBridgeGetters__factory.prototype.getDeployTransaction = function (overrides) {
        return _super.prototype.getDeployTransaction.call(this, overrides || {});
    };
    NFTBridgeGetters__factory.prototype.attach = function (address) {
        return _super.prototype.attach.call(this, address);
    };
    NFTBridgeGetters__factory.prototype.connect = function (signer) {
        return _super.prototype.connect.call(this, signer);
    };
    NFTBridgeGetters__factory.createInterface = function () {
        return new utils.Interface(_abi);
    };
    NFTBridgeGetters__factory.connect = function (address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    };
    NFTBridgeGetters__factory.bytecode = _bytecode;
    NFTBridgeGetters__factory.abi = _abi;
    return NFTBridgeGetters__factory;
}(ContractFactory));
export { NFTBridgeGetters__factory };
