/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { utils, Contract, ContractFactory } from "ethers";
var _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint32",
                name: "oldGuardianIndex",
                type: "uint32",
            },
            {
                indexed: false,
                internalType: "uint32",
                name: "newGuardianIndex",
                type: "uint32",
            },
        ],
        name: "LogGuardianSetChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "emitter_address",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint32",
                name: "nonce",
                type: "uint32",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "payload",
                type: "bytes",
            },
        ],
        name: "LogMessagePublished",
        type: "event",
    },
];
var _bytecode = "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea2646970667358221220626528f6b2a5d17ec81382b9caf4870953d2f61fe7d4009ba10f8cb47a0a5ea164736f6c63430008040033";
var Events__factory = /** @class */ (function (_super) {
    __extends(Events__factory, _super);
    function Events__factory(signer) {
        return _super.call(this, _abi, _bytecode, signer) || this;
    }
    Events__factory.prototype.deploy = function (overrides) {
        return _super.prototype.deploy.call(this, overrides || {});
    };
    Events__factory.prototype.getDeployTransaction = function (overrides) {
        return _super.prototype.getDeployTransaction.call(this, overrides || {});
    };
    Events__factory.prototype.attach = function (address) {
        return _super.prototype.attach.call(this, address);
    };
    Events__factory.prototype.connect = function (signer) {
        return _super.prototype.connect.call(this, signer);
    };
    Events__factory.createInterface = function () {
        return new utils.Interface(_abi);
    };
    Events__factory.connect = function (address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    };
    Events__factory.bytecode = _bytecode;
    Events__factory.abi = _abi;
    return Events__factory;
}(ContractFactory));
export { Events__factory };
