/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { utils, Contract, ContractFactory } from "ethers";
var _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "AdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "beacon",
                type: "address",
            },
        ],
        name: "BeaconUpgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "Upgraded",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                internalType: "uint16",
                name: "chainId",
                type: "uint16",
            },
            {
                internalType: "address",
                name: "wormhole",
                type: "address",
            },
            {
                internalType: "uint16",
                name: "governanceChainId",
                type: "uint16",
            },
            {
                internalType: "bytes32",
                name: "governanceContract",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "tokenImplementation",
                type: "address",
            },
            {
                internalType: "address",
                name: "WETH",
                type: "address",
            },
        ],
        name: "setup",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
var _bytecode = "0x608060405234801561001057600080fd5b50610299806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c80633bfa063814610030575b600080fd5b61004361003e3660046101e5565b610045565b005b60028054600080546001600160a01b0389166001600160a01b031990911617905561ffff868116620100000263ffffffff19909216908916171790556003839055600180546001600160a01b0319166001600160a01b038416179055600480546001600160a01b0319166001600160a01b0383161790556100c5876100ce565b50505050505050565b6100d78161010e565b6040516001600160a01b038216907fbc7cd75a20ee27fd9adebab32041f755214dbc6bffa90cc0225b39da2e5c2d3b90600090a250565b803b6101765760405162461bcd60e51b815260206004820152602d60248201527f455243313936373a206e657720696d706c656d656e746174696f6e206973206e60448201526c1bdd08184818dbdb9d1c9858dd609a1b606482015260840160405180910390fd5b7f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc80546001600160a01b0319166001600160a01b0392909216919091179055565b80356001600160a01b03811681146101ce57600080fd5b919050565b803561ffff811681146101ce57600080fd5b600080600080600080600060e0888a0312156101ff578283fd5b610208886101b7565b9650610216602089016101d3565b9550610224604089016101b7565b9450610232606089016101d3565b93506080880135925061024760a089016101b7565b915061025560c089016101b7565b90509295989194975092955056fea2646970667358221220a1de272bca03ec9622999940e0a2689c62473839bc61ab636883f82cb035e80764736f6c63430008040033";
var BridgeSetup__factory = /** @class */ (function (_super) {
    __extends(BridgeSetup__factory, _super);
    function BridgeSetup__factory(signer) {
        return _super.call(this, _abi, _bytecode, signer) || this;
    }
    BridgeSetup__factory.prototype.deploy = function (overrides) {
        return _super.prototype.deploy.call(this, overrides || {});
    };
    BridgeSetup__factory.prototype.getDeployTransaction = function (overrides) {
        return _super.prototype.getDeployTransaction.call(this, overrides || {});
    };
    BridgeSetup__factory.prototype.attach = function (address) {
        return _super.prototype.attach.call(this, address);
    };
    BridgeSetup__factory.prototype.connect = function (signer) {
        return _super.prototype.connect.call(this, signer);
    };
    BridgeSetup__factory.createInterface = function () {
        return new utils.Interface(_abi);
    };
    BridgeSetup__factory.connect = function (address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    };
    BridgeSetup__factory.bytecode = _bytecode;
    BridgeSetup__factory.abi = _abi;
    return BridgeSetup__factory;
}(ContractFactory));
export { BridgeSetup__factory };
