/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { utils, Contract, ContractFactory } from "ethers";
var _abi = [
    {
        inputs: [
            {
                internalType: "uint32",
                name: "index",
                type: "uint32",
            },
        ],
        name: "getGuardianSet",
        outputs: [
            {
                components: [
                    {
                        internalType: "address[]",
                        name: "keys",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32",
                        name: "expirationTime",
                        type: "uint32",
                    },
                ],
                internalType: "struct Structs.GuardianSet",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getCurrentGuardianSetIndex",
        outputs: [
            {
                internalType: "uint32",
                name: "",
                type: "uint32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getGuardianSetExpiry",
        outputs: [
            {
                internalType: "uint32",
                name: "",
                type: "uint32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "hash",
                type: "bytes32",
            },
        ],
        name: "governanceActionIsConsumed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "impl",
                type: "address",
            },
        ],
        name: "isInitialized",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "chainId",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "governanceChainId",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "governanceContract",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "messageFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "emitter",
                type: "address",
            },
        ],
        name: "nextSequence",
        outputs: [
            {
                internalType: "uint64",
                name: "",
                type: "uint64",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
var _bytecode = "0x608060405234801561001057600080fd5b50610399806100206000396000f3fe608060405234801561001057600080fd5b506004361061009e5760003560e01c8063b172b22211610066578063b172b22214610173578063d60b347f1461017b578063eb8d3f12146101a7578063f951975a146101bd578063fbe3c2cd146101dd57600080fd5b80631a90a219146100a35780631cfe7951146100ba5780632c3c02a4146100d95780634cf842b51461010c5780639a8a059214610158575b600080fd5b6007545b6040519081526020015b60405180910390f35b60035463ffffffff165b60405163ffffffff90911681526020016100b1565b6100fc6100e73660046102bc565b60009081526005602052604090205460ff1690565b60405190151581526020016100b1565b61013f61011a36600461028e565b6001600160a01b031660009081526004602052604090205467ffffffffffffffff1690565b60405167ffffffffffffffff90911681526020016100b1565b60005461ffff165b60405161ffff90911681526020016100b1565b6001546100a7565b6100fc61018936600461028e565b6001600160a01b031660009081526006602052604090205460ff1690565b600354640100000000900463ffffffff166100c4565b6101d06101cb3660046102d4565b6101ef565b6040516100b191906102f8565b60005462010000900461ffff16610160565b60408051808201825260608082526000602080840182905263ffffffff8616825260028152908490208451815492830281018401865294850182815293949390928492849184018282801561026d57602002820191906000526020600020905b81546001600160a01b0316815260019091019060200180831161024f575b50505091835250506001919091015463ffffffff1660209091015292915050565b60006020828403121561029f578081fd5b81356001600160a01b03811681146102b5578182fd5b9392505050565b6000602082840312156102cd578081fd5b5035919050565b6000602082840312156102e5578081fd5b813563ffffffff811681146102b5578182fd5b6020808252825160408383015280516060840181905260009291820190839060808601905b808310156103465783516001600160a01b0316825292840192600192909201919084019061031d565b5063ffffffff84880151166040870152809450505050509291505056fea2646970667358221220cecd9d60968cd81bbfe697aacd5e3c8fdc1f14f1afef8cbc9191f6c9c55a391564736f6c63430008040033";
var Getters__factory = /** @class */ (function (_super) {
    __extends(Getters__factory, _super);
    function Getters__factory(signer) {
        return _super.call(this, _abi, _bytecode, signer) || this;
    }
    Getters__factory.prototype.deploy = function (overrides) {
        return _super.prototype.deploy.call(this, overrides || {});
    };
    Getters__factory.prototype.getDeployTransaction = function (overrides) {
        return _super.prototype.getDeployTransaction.call(this, overrides || {});
    };
    Getters__factory.prototype.attach = function (address) {
        return _super.prototype.attach.call(this, address);
    };
    Getters__factory.prototype.connect = function (signer) {
        return _super.prototype.connect.call(this, signer);
    };
    Getters__factory.createInterface = function () {
        return new utils.Interface(_abi);
    };
    Getters__factory.connect = function (address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    };
    Getters__factory.bytecode = _bytecode;
    Getters__factory.abi = _abi;
    return Getters__factory;
}(ContractFactory));
export { Getters__factory };
